import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormControl, Stack, TextField, Typography } from "@mui/material";
import useTranslation from 'next-translate/useTranslation';
import PrimaryBtn from "@/components/common/buttons/PrimaryBtn";
import { PasswordForgetLink } from "@/content/login/passwordForget";

const classes = {
    formContainer: {
        backgroundColor: "#EFEFEF",
        padding: "10px 0 10px 0",
        margin: "20px auto 40px",
        marginLeft: { "md": 20 }
    },
    formHeadline: {
        fontSize: "1.6em",
    },
    inputContainer: {
        textAlign: "center",
    },
    logo: {
        width: "15%",
        marginLeft: { "xs": 16, "sm": 23, "md": 30 },
    },
    formControl: {
        width: "100%",
    },
    button: {
        marginTop: "1.5em",
        padding: "10px",
        fontWeight: "bolder",
    },
    textfield: {
        margin: "10px auto 5px",
    },
    Errormessage: {
        color: "#E61C1C",
        textAlign: "left",
        marginTop: 0,
        marginBottom: "10px",
        fontSize: "12px",
    },
    formMessage_error: {
        maxWidth: "max-content",
        padding: "0.5em",
        backgroundColor: "rgba(255, 0,0,0.2)",
        borderRadius: "4px",
        textAlign: "center"
    },
    formMessage_success: {
        width: "90%",
        margin: "10px auto 10px",
        padding: "12px 0px 1px 0px",
        backgroundColor: "#e47839",
        borderRadius: "4px",
    },
};

interface CustomForm {
    schema: any;
    onSubmit: any;
    errorMessage: string;
    showErrorMessage: boolean;
    SubmitMessage: string;
    defaultValue?: any;
    title?: string;
    whiteBackground?: boolean;
    isLoading?: boolean;
    onClose?: VoidFunction;
    login?: boolean;
}

const CustomFrom = (props: CustomForm) => {
    const fields = props.schema.fields
    const { t } = useTranslation("translation");
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(props.schema),
    });

    const fieldsComponent: JSX.Element[] = [];
    let counter = 1;
    for (const field in fields) {
        fieldsComponent.push(

            <FormControl sx={classes.formControl} key={counter}>
                <TextField
                    label={field}
                    variant="filled"
                    multiline={field === t("contact.schema.message") ? true : false}
                    minRows={field === t("contact.schema.message") ? 5 : 1}
                    type={field === t("login.labels.password") ? "password" : "text"}
                    defaultValue={props.defaultValue ? props.defaultValue[field] : ""}
                    fullWidth
                    sx={{ ...classes.textfield, backgroundColor: props?.whiteBackground ? "#EFEFEF" : "" }}
                    {...register(field)} />
                {errors[field] && <Box component="span" sx={classes.Errormessage}>{errors[field]?.message as string}</Box>}

            </FormControl>
        );
        counter++;
    }

    return (
        <>
            {props.showErrorMessage && (
                <Box component="div" sx={classes.formMessage_error}>
                    <p>
                        {props.errorMessage}
                    </p>
                </Box>
            )}

            <form onSubmit={handleSubmit(props.onSubmit)}>
                {props.title && <Typography variant="h5"
                    component="h2"
                    gutterBottom>{props.title}</Typography>}
                <>{fieldsComponent}</>
                {props.login &&
                    <Stack alignItems="flex-end" mt={1} mb={0}>
                        <PasswordForgetLink onClose={() => { if (props.onClose) { props?.onClose() } }} />
                    </Stack>
                }
                <PrimaryBtn
                    onClick={handleSubmit(props.onSubmit)}
                    style={{ marginTop: "1em" }}
                    isLoading={props.isLoading}>
                    {props.SubmitMessage}
                </PrimaryBtn>
            </form>

        </>
    );
}

export default CustomFrom;
