import { withFirebase } from "@/components/Firebase";
import * as ROUTES from "@/constants/routes";
import styling from "./styles/login.module.scss";
import {
    Divider,
    Box,
    Grid,
    IconButton,
    Typography,
    Button,
    Stack,
    CircularProgress,
} from "@mui/material";
import { SignUpEmail } from "./EmailLoginForm";
import GoogleBtn from "@/components/common/buttons/GoogleBtn";
import FacebookBtn from "@/components/common/buttons/FacebookBtn";
import CreateNewUser from "./CreateNewUser";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import ra from "@/Images/ra/RunAgain_Horizontal_Black.png";

import { ReactComponent as Exit } from "@/assets/exit.svg";
import { useState } from "react";

const classes = {
    fbButton: {
        backgroundColor: "#0866ff",
        color: "white",
        "&:hover": {
            backgroundColor: "rgba(8, 103, 255, 0.8)",
        },
    },
    subtitle: {
        fontWeight: "700",
        color: "#808080",
        textAlign: "center",
        width: "100%",
    },
    lines: {
        height: "1px",
        backgroundColor: "gray",
        width: "30%",
    },
};

function SignInPage(props: any) {
    const { t } = useTranslation("translation");
    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box component="div" p={4} className={styling.cardContent}>
                <Box pt={0} pb={2} className={styling.topBox}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Image
                            src={ra.src}
                            height="30"
                            width="170"
                            className={styling.cardLogo}
                            alt="RunAgain"
                        />
                        <Exit onClick={props.onClose} />
                    </Grid>
                    <Box pt={2} pb={3}>
                        <Divider />
                    </Box>
                </Box>
                <Box pt={0} pb={2} className={styling.logIn__headline}>
                    <h3>{t("login.signInPage.title")}</h3>
                </Box>

                <SignInForm onClose={props.onClose} createNewUser={props.createNewUser} />
                <ConsentToTerms onClose={() => props.onClose()} />
            </Box>
        </Box>
    );
}

const SignInFormBase = (props: any) => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation("translation");

    const signIn = async (loginMethod: string) => {
        try {
            localStorage.setItem("login_location", window.location.href);
            await props.firebase.customSignIn(loginMethod);
            setLoading(false);
            props.onClose();
        } catch {
            setLoading(false);
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            {!loading && (
                <Grid item xs={12}>
                    <Stack spacing={2}>
                        <Button
                            onClick={() => {
                                setLoading(true);
                                signIn("Google");
                            }}
                            sx={{
                                backgroundColor: "white",
                                color: "black",
                                border: "0.5px solid gray",
                            }}
                        >
                            <GoogleBtn />{" "}
                            {props.createNewUser
                                ? t("login.signUpPage.ctaGoogle")
                                : t("login.signInPage.ctaGoogle")}
                        </Button>
                        <Button
                            onClick={() => {
                                setLoading(true);
                                signIn("FaceBook");
                            }}
                            sx={classes.fbButton}
                        >
                            <FacebookBtn />
                            {props.createNewUser
                                ? t("login.signUpPage.ctaFacebook")
                                : t("login.signInPage.ctaFacebook")}
                        </Button>
                    </Stack>

                    <Box
                        pt={6}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={classes.lines}></Box>
                        <Typography variant="body1" sx={classes.subtitle}>
                            {props.createNewUser
                                ? t("login.signUpPage.subTitle")
                                : t("login.signInPage.option")}
                        </Typography>
                        <Box sx={classes.lines}></Box>
                    </Box>

                    <Box
                        pt={1}
                        sx={{
                            maxWidth: "450px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 auto",
                            gap: "1em",
                        }}
                    >
                        <SignUpEmail
                            onClose={() => props.onClose()}
                            createNewUser={props.createNewUser}
                        />
                    </Box>
                </Grid>
            )}
            {loading && (
                <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column", alignItems: "center", pt: 2 }}
                >
                    <CircularProgress sx={{ mb: 2 }} />
                    <Typography>{t("login.signingIn")}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

const SignUpPageBase = (props: any) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("translation");

    const signIn = async (loginMethod: string) => {
        localStorage.setItem("login_location", window.location.href);
        try {
            await props.firebase.customSignIn(loginMethod);
            setLoading(false);
            props?.onClose();
        } catch {
            setLoading(false);
        }
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: "90%"}}
        >
            {!loading && (
                <Grid item xs={12}>
                    <Stack spacing={2}>
                        <Button
                            onClick={() => {
                                setLoading(true);
                                signIn("Google");
                            }}
                            sx={{
                                backgroundColor: "white",
                                color: "black",
                                border: "0.5px solid gray",
                            }}
                        >
                            <GoogleBtn />
                            {t("login.signUpPage.ctaGoogle")}
                        </Button>
                        <Button
                            onClick={() => {
                                setLoading(true);
                                signIn("FaceBook");
                            }}
                            sx={classes.fbButton}
                        >
                            <FacebookBtn />
                            {t("login.signUpPage.ctaFacebook")}
                        </Button>
                    </Stack>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            pt: 6,
                        }}
                    >
                        <Box sx={classes.lines}></Box>
                        <Typography variant="body1" sx={classes.subtitle}>
                            {t("login.signUpPage.subTitle")}
                        </Typography>
                        <Box sx={classes.lines}></Box>
                    </Box>

                    <SignUpEmail onClose={() => props.onClose()} createNewUser={true} />
                </Grid>
            )}
            {loading && (
                <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column", alignItems: "center", pt: 2 }}
                >
                    <CircularProgress sx={{ mb: 2 }} />
                    <Typography>{t("login.signingIn")}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

const RegisterComponent = (props: any) => {
    return (
        <Box sx={{ height: "100vh", overflow: { xs: "scroll", md: "hidden" }, pb:5 }}>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{ position: "absolute", top: { md: 20, xs: 20 }, right: 20 }}
            >
                <Exit />
            </IconButton>
            <CreateNewUser
                login={props.login}
                onClose={() => props.onClose()}
                category={props.category}
            />
        </Box>
    );
};

const ConsentToTerms = ({ onClose }: any) => {
    const { t } = useTranslation("translation");
    return (
        <Box maxWidth="450px" margin="auto" pb={2} onClick={() => onClose()}>
            <Typography variant="body2" textAlign="center" color="gray" onClick={() => onClose()}>
                {t("login.signInPage.consent")}{" "}
                <a href={ROUTES.PRIVACY_POLICY} className={styling.forgotPassword_link}>
                    {t("login.signInPage.privacy")}
                </a>{" "}
                {t("login.signInPage.and")}{" "}
                <a href={ROUTES.CONDITONS_OF_SALES} className={styling.forgotPassword_link}>
                    {t("login.signInPage.general")}
                </a>{" "}
                {t("login.signInPage.terms")}
            </Typography>
        </Box>
    );
};

const SignInForm = withFirebase(SignInFormBase);
const SignUpPage = withFirebase(SignUpPageBase);

export default withFirebase(SignInPage);
export { SignInForm, SignUpPage, RegisterComponent, ConsentToTerms };
