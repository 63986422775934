import { Grid, Box, Typography } from "@mui/material";
import { withFirebase } from "@/components/Firebase";
import { SignUpPage, SignInForm } from "./login";
import * as ROUTES from "@/constants/routes";
import { useCallback, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { mutate } from "swr";
import { sleep } from "@/helpers/sleep";
import withAuthUser from "@/helpers/authHandler/withAuthUser";
import useAuthUser from "@/helpers/authHandler/useAuthUser";
import { ActivateOrganizerRole } from "@/HttpCalls/Organizer/organizerAdmin";
import * as roles from "@/constants/roles";
import * as category_types from "@/constants/category_types";

const classes = {
    container: {
        paddingTop: "60px",
    },
    reasons: {
        backgroundColor: "#FF8640",
        borderRadius: {
            xs: "0px 0px 10px 10px",
            md: "10px 0px 0px 10px",
            lg: "0px, 0px, 0px, 0px",
        },
    },
    signup: {
        // minHeight: { md: "auto", xs: "auto" },
        height: { xs: "max-content", md: "100vh" },
        backgroundColor: "#fcfcfc",
        borderRadius: {
            xs: "10px 10px 0px 0px",
            md: "0px 10px 10px 0px",
            lg: "0px, 0px, 0px, 0px",
        },
    },
    reasonsTitle: {
        paddingBottom: "25px",
    },
    reasonTexts: {
        display: "flex",
        paddingBottom: "25px",
    },
    icons: {
        height: "30px",
        width: "30px",
        mr: 2,
    },
    reasonItem: {
        width: "100%",
    },
    reasonTypography: {
        fontSize: "18px",
        lineHeight: "25px",
        fontWeight: "700",
    },
    mainTitle: {
        pt: 3,
        textAlign: "center",
        fontWeight: {xs: "500", md:"700"},
    },
    subtitle: {
        fontWeight: "700",
        fontSize: "1em",
        color: "#808080",
        textAlign: "left",
        mt: 1,
        p: 1,
    },
};

const CreateNewUser = (props: any) => {
    const [value, setValue] = useState(props.login ? "login" : "signup");
    const { t } = useTranslation("translation");
    const router = useRouter();
    const authUser = useAuthUser();


    const checkAuth = useCallback(async () => {
        for (let counter = 0; counter <= 5; counter++) {
            try {
                counter += 1;
                const token = await authUser?.getIdToken();

                if (token) {
                    const user_data = await props.firebase.getDocument("Users", authUser.id);
                    if (user_data) {
                        mutate("userData", user_data, false);
                        const getOrganizerAccess = sessionStorage.getItem("getOrganizerAccess");
                        if (getOrganizerAccess) {
                            sessionStorage.removeItem("getOrganizerAccess");
                            // Trigger endpoint for activating access to organizer portal

                            if (user_data?.role?.includes(roles.ORGANIZER)) {
                                router.push(ROUTES.ORGANIZER_PORTAL);
                            } else {
                                const response = await ActivateOrganizerRole(props.firebase);
                                if (response?.status === 200) {
                                    props.firebase.logFirebaseEvent(
                                        null,
                                        "new_organizer",
                                        true,
                                        "organizer"
                                    );
                                    props.firebase.refetchAuthToken();
                                    router.push(ROUTES.ORGANIZER_PORTAL);
                                }
                            }
                        }

                        if (
                            router.pathname.includes("signup") ||
                            router.pathname.includes("login")
                        ) {
                            if (router.query && router.query?.fromOrganizer) {
                                router.push(ROUTES.ORGANIZER_PORTAL);
                            } else if (props.login) {
                                router.push(ROUTES.PROFILE);
                            }
                        }
                        break;
                    } else {
                        await sleep(200);
                    }
                } else {
                    await sleep(200);
                }
            } catch (e: any) {
                await sleep(200);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser, props.firebase, router]);

    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    useEffect(() => {
        checkAuth();
    }, [checkAuth, router]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const onClose = async () => {
        await checkAuth();
        if (Object.keys(props).includes("onClose")) {
            props?.onClose();
        }
    };

    useEffect(() => {
        props.firebase.logFirebaseEvent(null, "signup_page", true, "user");
        props.firebase.logPageViewEvent(props?.login ? "Login to RunAgain" : "Create a profile");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCorrectIndex = () => {
        switch (props.category) {
            case category_types.FAVOURITE:
                return 0;
            case category_types.COMMENTS:
                return 1;
            case category_types.IMAGE:
                return 1;

            case category_types.RACE_PLAN:
                return 2;
            case category_types.FRIEND:
                return 3;
            case category_types.LIKES:
                return 3;
            default:
                return 4;
        }
    };


    const subTitles:any[] = t("signUp.subtitle." + getCorrectIndex(), null, {returnObjects: true});


    return (
        <Grid container sx={{ height: "100vh", mb:5, pb:5 }}>
            <Grid item xs={12} sx={classes.signup} pt="50px" order={{ xs: 1, md: 2 }}>
                <Tabs
                    centered
                    value={value}
                    onChange={handleChange}
                    aria-label="tab to change between login and signup"
                    sx={{
                        mb: 2,
                        borderBottom: 5,
                        borderColor: "#efefef",
                        minWidth: "300px",
                        maxWidth: "350px",
                        margin: "0 auto",
                    }}
                >
                    <Tab
                        label="Login"
                        value={"login"}
                        sx={{ fontSize: "16px", width: "50%", fontWeight: "bold" }}
                    />
                    <Tab
                        label="Signup"
                        value={"signup"}
                        sx={{ fontSize: "16px", width: "50%", fontWeight: "bold" }}
                    />
                </Tabs>
                {value === "signup" && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        ml="auto"
                        mr="auto"
                        sx={{ height: "90%", width: "80%", overflowY: {xs:"scroll", md: "hidden"} }}
                    >
                        <Grid item xs={12} order={{ xs: 1, md: 0 }}>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Box sx={{ width: { xs: "auto" } }}>
                                    <Typography variant="h4" sx={classes.mainTitle}>
                                        {t("signUp.title." + getCorrectIndex())}
                                    </Typography>
                                    
                                    {Array.isArray(subTitles) && subTitles?.map((subTitle:string, index:number)=>
                                     <Typography variant="body1" key={index} component="li" sx={classes.subtitle}>{subTitle}</Typography>
                                    )}
                                    
                                </Box>
                            </Box>


                        </Grid>

                        <Grid item xs={12} sm={6} md={4} order={{ xs: 0, md: 1 }} sx={{pt:2}}>
                            <SignUpPage onClose={() => onClose()} category={props.category} />
                        </Grid>
                        <Grid item pb={2}></Grid>
                    </Grid>
                )}
                {value === "login" && (
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        ml="auto"
                        mr="auto"
                        sx={{ height: "100%", width: "80%", overflow: "scroll" }}
                    >
                        <Grid item pb={3}>
                            <Typography variant="h4" sx={classes.mainTitle}>
                                {t("login.signInPage.mainTitle")}
                            </Typography>
                            <Typography variant="body1" sx={classes.subtitle}>
                                {t("login.signInPage.title")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <SignInForm
                                onClose={() => onClose()}
                                createNewUser={props.createNewUser}
                                location="login"
                            />
                        </Grid>
                        <Grid item pb={2}></Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default withAuthUser()(withFirebase(CreateNewUser));
