import * as ROUTES from '@/constants/routes';
import { TextField, Button, FormControl, Box, Dialog } from '@mui/material';
import styling from './styles/login.module.scss';
import { withAuthentication } from '@/components/Session';
import MessageBox from '@/components/common/successMessage/MessageBox';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import Link from 'next/link';

const PasswordForgetPage = () => {
  const { t } = useTranslation("translation");
  return (
    <Box className={styling.forgotPassword__Container} boxShadow={3}>
      <h1>{t("login.reset.title")}</h1>
      <h2>{t("login.reset.subTitle")}</h2>
      <p>{t("login.reset.helperText")}</p>
      <PasswordForgetForm />
    </Box>
  );
};

const INITIAL_STATE: any = {
  email: '',
  error: null,
  sucess: false
};
const PasswordForgetFormBase = (props: any) => {
  const [state, setState] = useState({ ...INITIAL_STATE });
  const { t } = useTranslation("translation");

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const { email } = state;
    if (email.includes("@")) {
      const result = await props.firebase.doPasswordReset(email);
      if (result) {
        setState({ ...INITIAL_STATE });
        upDateState("sucess", true);
      } else {
        upDateState("error", "Noe feilet, vennligst prøv nytt!");
      }
    } else {
      upDateState("error", "Noe feilet, vennligst prøv nytt!");
    }

    return true;
  };

  const upDateState = (name: string, value: any) => {
    let old_state: any = { ...state };
    old_state[name] = value;
    setState(old_state);
  }

  const onChange = (event: any) => {
    upDateState(event.target.name, event.target.value);
  };

  const { email, error } = state;
  const isInvalid = email === '';
  return (
    <>
      <form onSubmit={onSubmit}>
        <FormControl fullWidth className={styling.formControl}>
          <TextField
            variant="outlined"
            name="email"
            value={state.email}
            onChange={onChange}
            type="text"
            className={styling.forgotPasswordField}
            placeholder={t("login.labels.email")}
          />
          <Button disabled={isInvalid} type="submit" variant="contained" className={styling.resetpassword__Button}>
            {t("login.reset.ctaText")}
          </Button>
          {error && <p>{error}</p>}
        </FormControl>
      </form>
      <Dialog open={state.sucess} onClose={() => upDateState("sucess", false)}>
        <MessageBox timeout={10000} onClose={() => upDateState("sucess", false)} text={t("login.reset.success")} subText={t("login.reset.successSubText")} />

      </Dialog>
    </>
  );

}

const PasswordForgetLink = ({ onClose }: any) => {
  const { t } = useTranslation("translation");
  return (
    <p >
      <Link href={ROUTES.PASSWORD_FORGET} onClick={() => onClose()} className={styling.forgotPassword_link}>{t("login.reset.forgotten")}</Link>
    </p>
  );
};

export default PasswordForgetPage;
const PasswordForgetForm = withAuthentication(PasswordForgetFormBase);
export { PasswordForgetPage, PasswordForgetLink };
