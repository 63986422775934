import { Component as ReactComponent } from "react";

import { withFirebase } from "../Firebase";
import AuthUserContext from "./context";
import { withRouter } from "./withAuthorization";

const withAuthentication = (Component: any) => {
  class WithAuthentication extends ReactComponent<any, any> {
    _isMounted = false;
    listener: any;
    constructor(props: any) {
      super(props);
      let localStorageValue: any = null;
      if (typeof window !== 'undefined') {
        // localStorageValue = localStorage.getItem("authUser");
      }
      this.state = {
        authUser: JSON.parse(localStorageValue),
        redirect: false
      };
    }

    componentDidMount() {
      this._isMounted = true;
      if (((this.state.authUser === undefined || this.state.authUser === null) && !this.state.redirect)) {
        this.listener = this.props.firebase?.onAuthUserListener(
          (authUser: any) => {
            if (this._isMounted) {
              // localStorage.setItem("authUser", JSON.stringify(authUser));
              this.setState({ authUser: authUser, redirect: true });

              
            }
          },
          () => {
            if (this._isMounted) {
              // localStorage.removeItem("authUser");
              this.setState({ authUser: null, redirect: false });
            }
          }
        );
      }

    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return (withRouter(withFirebase(WithAuthentication)));
};

export default withAuthentication;
