import { useState } from "react";
import { withFirebase } from "@/components/Firebase";
import { createTheme } from "@mui/material/styles";
import * as yup from "yup";
import CustomFrom from "@/components/Forms/form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ConsentToTerms } from "./login";
import useTranslation from "next-translate/useTranslation";
import CircularProgress from '@mui/material/CircularProgress';
import { useRouter } from "next/router";
import { PROFILE } from "@/constants/routes";
import { Box, Button, FormControl, Grid, TextField } from "@mui/material";

const theme = createTheme();
const classes = {
    formContainer: {
        backgroundColor: "#ffff",
        padding: "20px 0 20px 0",
        margin: "20px auto 40px",
        [theme.breakpoints.up("md")]: {
            marginLeft: "20px",
            marginTop: "53px",
        },
    },
    formHeadline: {
        fontSize: "1.6em",
    },
    inputContainer: {
        textAlign: "center",
    },
    logo: {
        width: "15%",
        marginLeft: 16,
        [theme.breakpoints.up("sm")]: {
            marginLeft: "23px",
        },
        [theme.breakpoints.up("md")]: {
            marginLeft: "30px",
        },
    },
    formControl: {
        width: "100%",
    },
    button: {
        width: "100%",
        marginTop: "10px",
    },
    createButton: {
        lineHeight: "28px",
        width: '100%',
    },
    textfield: {
        margin: "10px auto 5px",
    },
    Errormessage: {
        color: "#E61C1C",
        textAlign: "left",
        marginTop: "0",
        marginBottom: "10px",
        fontSize: "12px",
    },
    formMessage_error: {
        width: "90%",
        margin: "10px auto 10px",
        padding: "12px 0px 1px 0px",
        backgroundColor: "##E61C1C",
        borderRadius: "4px",
    },
    formMessage_success: {
        width: "90%",
        margin: "10px auto 10px",
        padding: "12px 0px 1px 0px",
        backgroundColor: "#e47839",
        borderRadius: "4px",
    },
};



const EmailLoginForm = (props: any) => {
    const [errorMessage, setErrorMessage] = useState(false);
    const [statusCreateUser, setStatusCreateUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayNoEmailFound, setDisplayNoEmailFound] = useState(false);
    const [createNewUser, setCreateNewUser] = useState(props.createNewUser);
    const [savedValue, setSavedValue] = useState({});
    const { t } = useTranslation("translation");
    const router = useRouter();

    const shape: any = {};
    shape[t("login.labels.email")] = yup.string().required(t("login.schema.email")).email(t("login.schema.email"));
    shape[t("login.labels.password")] = yup.string().required(t("login.schema.password"))

    const schema = yup.object().shape(shape);

    const newUserShape: any = {};
    // newUserShape[t("login.labels.firstName")] = yup.string().required(t("login.newUser.schema.firstName"));
    // newUserShape[t("login.labels.lastName")] = yup.string().required(t("login.newUser.schema.lastName"));
    newUserShape[t("login.labels.email")] = yup.string().required(t("login.newUser.schema.email"));
    newUserShape[t("login.labels.password")] = yup.string().min(8, t("login.newUser.schema.passwordLengt")).required(t("login.newUser.schema.password"));

    const newUserSchema = yup.object().shape(newUserShape);

    async function onSubmit(data: any) {
        // Clear all message fields
        setLoading(true);
        setErrorMessage(false);
        setDisplayNoEmailFound(false);
        setSavedValue({
            "e-post": data[t("login.labels.email")]
        });

        try {
            const result = await props.firebase.checkIfEmailExists(data[t("login.labels.email")]);
            
            if (result) {
                await props.firebase.doSignInWithEmailAndPassword(data[t("login.labels.email")], data[t("login.labels.password")]);
                props.onClose();
            } else {
                setDisplayNoEmailFound(true);
                setLoading(false);
            }
        } catch (e) {
            console.log(e)
            setErrorMessage(true);
            setLoading(false);
        }
    }

    function StartProcessOfCreatingNewUser() {
        setLoading(false);
        setDisplayNoEmailFound(false);
        setCreateNewUser(true);

    }

    async function CreateNewUser(data: any) {
        setLoading(true);
        setErrorMessage(false);
        setDisplayNoEmailFound(false);
        const emailExists = await props.firebase.checkIfEmailExists(data[t("login.labels.email")]);
        if (emailExists) {
            setStatusCreateUser(true);
        }
        else {
            props.onClose();
            const result = await props.firebase.doCreateUserWithEmailAndPassword(data[t("login.labels.email")], data[t("login.labels.password")])
            if (result) {
                // props.firebase.logFirebaseEvent(null, "new_subscriber", true, "users");
                // await CreateUser(result, data[t("login.labels.firstName")], data[t("login.labels.lastName")], data[t("login.labels.email")], false);
                // const body = {
                //     // firstName: data[t("login.labels.firstName")],
                //     // lastName: data[t("login.labels.lastName")],
                //     email: data[t("login.labels.email")]
                // };
                // props.firebase.updateUser(body, result);
                // if (router.pathname.includes("signup") || router.pathname.includes("login")) {
                //     router.push(PROFILE);
                // }
               
            } else {
                setStatusCreateUser(true);
            }
        }
        
        setLoading(false);
    }

    return (
        <>
            {displayNoEmailFound && (
                <Box component="div" sx={classes.formMessage_error}>
                    <p>
                        {t("login.error")}
                    </p>
                    <Button variant="contained" type="button" color="secondary" onClick={StartProcessOfCreatingNewUser} fullWidth sx={classes.button}>{t("login.createNewUser")}</Button>
                </Box>
            )}

            {/* Login Form */}
            {!createNewUser &&
                <CustomFrom
                    schema={schema}
                    onSubmit={onSubmit}
                    errorMessage={t("login.login.errorMessage")}
                    showErrorMessage={errorMessage}
                    SubmitMessage={t("login.login.submitMessage")}
                    isLoading={loading}
                    onClose={() => props.onClose()}
                    login={true}
                />
            }

            {/* New user Form */}
            {createNewUser && !loading &&
                <CustomFrom
                    schema={newUserSchema}
                    onSubmit={CreateNewUser}
                    errorMessage={t("login.newUser.errorMessage")}
                    showErrorMessage={statusCreateUser}
                    SubmitMessage={t("login.newUser.submitMessage")}
                    defaultValue={savedValue}
                    isLoading={loading}
                    onClose={() => props.onClose()}
                />
            }

        </>
    );
}

const SignUpEmailBase = (props: any) => {
    const [displayEmailExists, setDisplayEmailExists] = useState(false);
    const [wrongProvider, setWrongProvider] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const { t } = useTranslation("translation");
    const router = useRouter();

    const newUserShape: any = {};
    newUserShape[t("login.labels.email")] = yup.string().email(t("login.newUser.schema.email")).required(t("login.newUser.schema.email"));
    newUserShape[t("login.labels.password")] = yup.string().min(8, t("login.newUser.schema.passwordLengt")).required(t("login.newUser.schema.password"));

    const newUserSchema = yup.object().shape(newUserShape);
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(newUserSchema),
    });


    async function CreateNewUser(data: any) {
        setIsloading(true);
        setDisplayEmailExists(false);
        setWrongProvider(false);
        const emailExists = await props.firebase.checkIfEmailExists(data[t("login.labels.email")]);
     
        if (emailExists) {
            try{
                const result = await props.firebase.doSignInWithEmailAndPassword(data[t("login.labels.email")], data[t("login.labels.password")]);
                if(result){
                    props.onClose();
                }else{
                    setDisplayEmailExists(true)
                }
            }catch{
                setDisplayEmailExists(true);
            }
        } else {
            try{
                const result = await props.firebase.doCreateUserWithEmailAndPassword(data[t("login.labels.email")], data[t("login.labels.password")])
                if (result) {
                    setDisplayEmailExists(false);
                    const body = {
                        email: data[t("login.labels.email")]
                    };
                    props.firebase.updateUser(body, result);

                    props.onClose();
                }
            }catch{
                setWrongProvider(true);
            }
        }
        setIsloading(false);

    }


    return (
        <>
            {displayEmailExists && (
                <Box component="div" sx={classes.formMessage_error}>
                    <p>
                        {t("login.emailExists")}
                    </p>
                </Box>
            )}
            {wrongProvider && (
                <Box component="div" sx={classes.formMessage_error}>
                    <p>
                        {t("login.wrongProvider")}
                    </p>
                </Box>
            )}
            <form onSubmit={handleSubmit(CreateNewUser)}>
                <Grid
                    container
                    justifyContent="center"
                    // maxWidth="350px"
                    alignItems="center" 
                >

                    <Grid item xs={12}>
                        <FormControl sx={classes.formControl} >
                            <TextField label={t("login.labels.email")} defaultValue={props.email ?? ""} variant="standard" type={"text"} fullWidth sx={classes.textfield} {...register(t("login.labels.email"))} />
                            {errors[t("login.labels.email")] && <Box component="span" sx={classes.Errormessage}>{errors[t("login.labels.email")]?.message as string}</Box>}
                        </FormControl>

                        <FormControl sx={classes.formControl} >
                            <TextField label={t("login.labels.password")} variant="standard" type={"password"} fullWidth sx={classes.textfield} {...register(t("login.labels.password"))} />
                            {errors[t("login.labels.password")] && <Box component="span" sx={classes.Errormessage}>{errors[t("login.labels.password")]?.message as string}</Box>}
                        </FormControl>
                    </Grid>
                    <Grid item pt={2}>
                        <ConsentToTerms onClose={() => props.onClose()} />
                        <Button variant="contained" disabled={isLoading} type="submit" color="primary" fullWidth sx={classes.createButton}>
                            {props.createNewUser ? t("login.newUser.submitMessage") : t("login.login.submitMessage")}
                            {isLoading && <CircularProgress color='inherit' size={16} sx={{ ml: 1 }} />}
                        </Button>
                    </Grid>

                </Grid>
            </form>
        </>
    );
}

const SignUpEmail = withFirebase(SignUpEmailBase);


export default withFirebase(EmailLoginForm);
export { SignUpEmail };