import firebaseTriggerUrls from "@/constants/firebaseTriggers";
import axios from "axios";
import { sleep } from "@/helpers/sleep";

const GetOrganizerData = async (organizerId: string, user: any) => {
    const URL = firebaseTriggerUrls.getOrganizerData;

    const token = await user.idToken;
    if (token) {
        const headers = {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        };
        const data: any = {
            organizer_id: organizerId,
        };

        let counter = 0;
        let condition = false;
        let response;

        response = await axios({
            headers: headers,
            url: URL,
            method: "post",
            timeout: 5000,
            data: data,
        });

        return response;
    } else {
        return null;
    }
};

async function ActivateOrganizerRole(user: any): Promise<any> {
    const URL = firebaseTriggerUrls.activateOrganizerRole;
    const token = await user.auth.currentUser?.getIdToken();
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 10000,
    });

    const response = promise.then((response: any) => response).catch((e) => e);
    return response;
}

async function GiveAccessToEvent(user: any, data:any): Promise<any> {
    const base_url =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/";
    const URL = base_url + "give_access_to_run";

    const token = await user.getIdToken();
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        data:data,
        timeout: 10000,
    });

    const response = promise.then((response: any) => response).catch((e) => e);
    return response;
}

async function RejectAccessToEvent(user: any, data:any): Promise<any> {
    const base_url =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/";
    const URL = base_url + "reject_claim_event";

    const token = await user.getIdToken();
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        data:data,
        timeout: 10000,
    });

    const response = promise.then((response: any) => response).catch((e) => e);
    return response;
}

export { GetOrganizerData, ActivateOrganizerRole, GiveAccessToEvent, RejectAccessToEvent };
