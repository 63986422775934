import Button from '@mui/material/Button';
import React, { FC } from 'react';
import googleIcon from "@/assets/login/google.svg"
import Image from 'next/image';
import { Box } from '@mui/material';

/***** TYPES *****/
interface GoogleBtnProps {
    onClick?: React.MouseEventHandler
};

/***** COMPONENT-FUNCTION *****/
const GoogleBtn: FC<GoogleBtnProps> = ({ onClick }): JSX.Element => {

    /*** Return statement ***/
    return (
        // <Button
        //     onClick={onClick}
        // >       
         <Box sx={{ width: "20px", height: "20px", mr: 1, position:'relative'}}>
            <Image fill src={googleIcon} alt="google icon" />
         </Box>
        // </Button>
    );
};

export default GoogleBtn;