import Button from '@mui/material/Button';
import React, { FC, ReactNode } from 'react';
import facebookIcon from "@/assets/login/facebook.svg"
import Image from 'next/image';
import Box from '@mui/material/Box';

/***** TYPES *****/
interface FacebookBtnProps {

    onClick?: React.MouseEventHandler
    type?: "submit" | "Reset",
    icon?: JSX.Element
    isLoading?: boolean,
};

/***** COMPONENT-FUNCTION *****/
const FacebookBtn: FC<FacebookBtnProps> = ({ onClick }): JSX.Element => {

    /*** Return statement ***/
    return (
        // <Button
        //     onClick={onClick}
        // >
            <Box sx={{ width: "20px", height: "20px", mr:1, position:'relative' }}>
                <Image fill src={facebookIcon} alt="google icon" style={{ objectFit: "cover" }} />
            </Box>

        // </Button>
    );
};

export default FacebookBtn;